import * as CryptoJS from 'crypto-js';

const isEncryptLocalStorage = true;
const key = process.env.REACT_APP_ENCRYPT_KEY;

export const encrypt = (value: string): string =>
  isEncryptLocalStorage ? CryptoJS.AES.encrypt(value, key).toString() : value;

export const decrypt = (value: string | null): string =>
  value
    ? isEncryptLocalStorage
      ? CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
      : value
    : '';
