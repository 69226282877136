import { useAuthVerify, UserInfoById } from 'commonQuery/commonQuery';
import {
  AuthVerifyResponse,
  ListApiResponse,
  User,
} from 'commonQuery/commonQueryDto';
import { MyContext } from 'MyContext';
import React, { Suspense, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from 'routes';
import { useLogout } from 'screens/Auth/AuthQuery';
import {
  ExcludePrivateRoute,
  ExcludePublicRoute,
  GetQueryParams,
  RedirectUrl,
  ROLES,
  SetQueryParams,
} from 'utils/constant';
import Storage from 'utils/Storage';

import Loader from './commonComponents/Loader';
import NotFound from './commonComponents/NotFound';

/* Load the Component */
const Layout = React.lazy(() => import('./layout/Layout'));
const Login = React.lazy(() => import('./screens/Login/Login'));
const Dashboard = React.lazy(() => import('./screens/Dashboard/Dashboard'));
const AddNewUser = React.lazy(() => import('./screens/User/AddNewUser'));
const Auth = React.lazy(() => import('./screens/Auth/Auth'));
const Password = React.lazy(
  () => import('./screens/Auth/Password/CreatePassword'),
);

const CommonRouter = () => {
  const location = useLocation();

  const [token, setToken] = useState('');
  const [userInfo, setUserInfo] = useState<User>();
  const navigate = useNavigate();
  const redirectUrl = GetQueryParams(RedirectUrl);
  const [loading, setLoading] = useState(true);

  const LogoutSuccess = () => {
    navigate(PublicRoutes.LOGIN, { replace: true });
  };
  const { mutate: LogoutMutate } = useLogout(LogoutSuccess);

  const handleLogoutApi = () => {
    LogoutMutate();
  };

  const FailureUser = () => {
    setLoading(true);
  };
  const SuccessUser = (data: ListApiResponse) => {
    setUserInfo(data?.data[0]);
    if (data?.data[0].roleId === ROLES.ADMIN) {
      setLoading(false);
      if (
        [PublicRoutes.LOGIN, PublicRoutes.CREATEPASSWORD, '/'].includes(
          location.pathname,
        )
      ) {
        navigate(PrivateRoutes.DASHBOARD, { replace: true });
        return;
      }
    } else if (data?.data[0]?.applicationAccess[0]?.application?.url) {
      window.location.href = `${data?.data[0]?.applicationAccess[0]?.application?.url}/auth?crfToken=${token}`;
      return;
    } else {
      setLoading(true);
    }
  };
  const { mutate: mutateUser } = UserInfoById(SuccessUser, FailureUser);
  const navigatePortal = (id: string) => {
    setLoading(true);
    mutateUser(id);
  };
  const redirectToLoginPage = () => {
    setLoading(false);
    if (ExcludePublicRoute.includes(location.pathname)) {
      return;
    }
    let params: URLSearchParams | string = '';
    if (redirectUrl) {
      params = SetQueryParams({ redirectUrl });
    }
    navigate(`${PublicRoutes.LOGIN}?${params.toString()}`, { replace: true });
  };
  const Success = (data: AuthVerifyResponse) => {
    if (!data.token) {
      redirectToLoginPage();
    } else {
      const info = Storage.tokenDecode();
      if (!info) {
        handleLogoutApi();
      }
      setToken(data.token);
      if (ExcludePrivateRoute.includes(location.pathname)) {
        setLoading(false);
        return;
      }
      if (redirectUrl) {
        window.location.href = `${redirectUrl}?crfToken=${data.token}`;
        return;
      } else {
        navigatePortal(info.id);
      }
    }
  };
  const Failure = () => {
    redirectToLoginPage();
  };
  const { mutate, isPending } = useAuthVerify(Success, Failure);
  useEffect(() => {
    if (!isPending) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;

  return (
    <MyContext.Provider value={{ userInfo }}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={PublicRoutes.LOGIN} element={<Login />} />
          <Route
            path={PublicRoutes.CREATEPASSWORD}
            element={<Password isChangePassword={false} isAdmin={false} />}
          />
          <Route path={PublicRoutes.AUTHSSO} element={<Auth />} />
          <Route
            path={PrivateRoutes.CHANGEPASSWORD}
            element={<Password isChangePassword={true} isAdmin={false} />}
          />
          <Route
            path={PrivateRoutes.DASHBOARD}
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path={PrivateRoutes.USER.ADD}
            element={
              <Layout>
                <AddNewUser />
              </Layout>
            }
          />
          <Route
            path={PrivateRoutes.USER.EDIT}
            element={
              <Layout>
                <AddNewUser />
              </Layout>
            }
          />
          <Route path="/" element={<Navigate to={PublicRoutes.LOGIN} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </MyContext.Provider>
  );
};

export default CommonRouter;
