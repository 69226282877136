export const PublicRoutes = {
  AUTHSSO: '/auth',
  CREATEPASSWORD: '/password',
  //   FORGOTPASSWORD: '/forgot-password',
  LOGIN: '/login',
  //   VERSIONHISTORY: '/version-history',
};

export const PrivateRoutes = {
  CHANGEPASSWORD: '/change-password',
  DASHBOARD: '/dashboard',
  USER: {
    ADD: '/addNewUser',
    EDIT: '/editUser/:userId',
  },
};

export const APIROUTES = {
  password: {
    CHANGEPASSWORD: 'password/change',
    EMAIL: 'password/validate',
    SETPASSWORD: 'password/set-password',
  },
};
