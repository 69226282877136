import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import typography from './Typography';

let theme = createTheme({
  typography,
});
theme = responsiveFontSizes(theme);

export default theme;
