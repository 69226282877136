import axios from 'axios';
import { CommonErrorMessage } from 'utils/constant';
import Storage from 'utils/Storage';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-riseit-browser': 'Brave on Linux',
    'x-riseit-datetime': '2024-08-23T06:59:27.799Z',
    'x-riseit-device': 'WEB',
    'x-riseit-location': 'India',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Storage.getItem(Storage.KEYS.AUTH_TOKEN, true);
    if (token && token.accessToken) {
      config.headers['Authorization'] = `Bearer ${token.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { refreshToken } = Storage.getItem(Storage.KEYS.AUTH_TOKEN, true);
        const { data } = await axiosInstance.post('/auth/refresh-token', {
          refreshToken: refreshToken,
        });
        const token = {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        };
        Storage.setItem(Storage.KEYS.AUTH_TOKEN, token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${
          data.accessToken as string
        }`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        Storage.clear();
        window.location.href = '/'; // Redirect to login
        return Promise.reject(refreshError);
      }
    }
    const errorMessage = error.response?.data?.error || {
      message: CommonErrorMessage,
    };
    return Promise.reject(errorMessage);
  },
);

export default axiosInstance;
