import { PrivateRoutes, PublicRoutes } from 'routes';

export enum ROLES {
  'ADMIN' = 1,
}

export const GetQueryParams = (key: string): string => {
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(key) || '';
};

export const SetQueryParams = (param: {
  [key: string]: string;
}): URLSearchParams => {
  return new URLSearchParams({
    ...param,
  });
};

export const ExcludePublicRoute = [
  PublicRoutes.LOGIN,
  PublicRoutes.CREATEPASSWORD,
];

export const CommonErrorMessage = 'something went wrong please try again';

export const ExcludePrivateRoute = [PrivateRoutes.CHANGEPASSWORD];

export const PasswordHashKey = 'hash';

export const RedirectUrl = 'redirectUrl';

export const SuccessMessage = {
  passwoord: {
    create: 'Password has been created successfully.',
    update: 'Password has been updated successfully',
  },
};
