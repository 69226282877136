import './Common.scss';

import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

const Loader: React.FC = () => {
  return (
    <Box className="loader-master">
      <CircularProgress sx={{ mb: 2 }} />
      <Typography variant="h6" color="textSecondary">
        Loading, please wait...
      </Typography>
    </Box>
  );
};

export default Loader;
