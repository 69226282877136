import { useMutation, useQuery } from '@tanstack/react-query';
import Storage from 'utils/Storage';

import axiosInstance from '../axios/config';
import {
  AuthVerifyResponse,
  ListApiResponse,
  OrderType,
  useUserListType,
} from './commonQueryDto';

// Define the API call to fetch the project list
export const fetchUserList = async (
  skip: number,
  take: number,
  sortBy: string,
  order: OrderType | null,
  search: string,
  status: string,
  id?: string,
) => {
  const endpoint = id ? `users?id=${id}` : 'users';
  const stateValue =
    status === 'all'
      ? null
      : status === 'active'
      ? true
      : status === 'in-active'
      ? false
      : false;
  const response = await axiosInstance.get<ListApiResponse>(endpoint, {
    params: {
      order,
      search: id ? undefined : search,
      skip: id ? undefined : skip,
      // Skip and take are only needed if no id is provided
      sortby: id ? undefined : sortBy && sortBy.toLowerCase(),
      status: id ? undefined : stateValue,
      take: id ? undefined : take,
    },
  });
  return response.data;
};

// Create a custom hook that uses useQuery
export const useUserList = ({
  page,
  rowsPerPage,
  orderBy,
  order,
  search,
  status,
  id,
  isSingle = false,
}: useUserListType) => {
  return useQuery({
    enabled: isSingle ? !!id : true,
    // Query key as an array
    queryFn: () =>
      fetchUserList(
        id ? 0 : page * rowsPerPage,
        rowsPerPage,
        orderBy,
        order,
        search,
        status,
        id,
      ),

    queryKey: id
      ? ['GetUser', id]
      : ['GetUserList', page, rowsPerPage, orderBy, order, search, status],
    refetchInterval: id ? false : 120000,
    staleTime: 0,
  });
};

const fetchUerById = async (id: string) => {
  const response = await axiosInstance.get<ListApiResponse>('users', {
    params: {
      id,
    },
  });
  return response.data;
};

export const UserInfoById = (
  successFn?: (data: ListApiResponse) => void,
  failureFn?: (error: any) => void,
) => {
  return useMutation({
    mutationFn: fetchUerById,
    onError: (error) => {
      if (failureFn) {
        failureFn(error);
      }
    },
    onSuccess: (data: ListApiResponse) => {
      if (successFn) {
        successFn(data);
      }
    },
  });
};

export const authVerify = async () => {
  const response = await axiosInstance.get('auth/verify');
  return response.data; // Assuming the response contains user data or a token
};
export const useAuthVerify = (
  successFn?: (data: AuthVerifyResponse) => void,
  failureFn?: (error: any) => void,
) => {
  return useMutation({
    mutationFn: authVerify,
    onError: (error) => {
      Storage.clear();
      if (failureFn) {
        failureFn(error);
      }
    },
    onSuccess: (data: AuthVerifyResponse) => {
      if (successFn) {
        successFn(data);
      }
    },
  });
};
