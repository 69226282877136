import { useMutation } from '@tanstack/react-query';
import Storage from 'utils/Storage';

import axiosInstance from '../../axios/config';

export const logout = async () => {
  const decodeToken: { refreshTokenId: string } = Storage.isTokenValidDetails();
  if (decodeToken) {
    const response = await axiosInstance.delete(
      `auth/logout/${decodeToken?.refreshTokenId}`,
    );
    return response.data; // Assuming the response contains user data or a token
  }
};
export const useLogout = (
  successFn?: (data: { success: boolean }) => void,
  failureFn?: (error: any) => void,
) => {
  return useMutation({
    mutationFn: logout,
    onError: (error) => {
      if (failureFn) {
        failureFn(error);
      }
    },
    onSuccess: (data: { success: boolean }) => {
      Storage.clear();
      if (successFn) {
        successFn(data);
      }
    },
  });
};
